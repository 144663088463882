import * as React from "react"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { utils } from "../utils.js"

import Container from "../helpers/Container.js"

import Sidebar from "../section/Sidebar.js"

import Page from "../elements/Page.js"
import Tag from "../elements/Tag.js"
import SharePost from "../elements/SharePost.js"
import BlogPost from "../elements/BlogPost.js"

const blogCol = "col-12 col-md-4 col-lg-12 col-xl-6 col-xxl-4 d-flex justify-content-center align-items-stretch mb-4"


function renderLatest(latests, length, id, recommendedIds) {
  return latests.filter(el => el.databaseId !== id && !recommendedIds.includes(el.id)).slice(0, length).map((node) => (
    <div className={blogCol} key={node.databaseId}>
      <BlogPost
        tag={node.categories.nodes[0].name}
        date={node.date}
        toPath={node.link}
        toPathTag={node.categories.nodes[0].link}
        title={node.title}
        excerpt={node.excerpt}
        key={node.id}
        imageSrc={(node.featuredImage) ? node.featuredImage.node : null}
        classInner="blog-index"
      />
    </div>
  ))
}

function renderRecomendations(id, recommendations, maxLength) {
  return recommendations.filter(el => el.databaseId !== id).slice(0, maxLength).map(node => (
      <div className={blogCol} key={node.id}>
        <BlogPost
          tag={node.categories.nodes[0].name}
          date={node.date}
          toPath={node.link}
          toPathTag={node.categories.nodes[0].link}
          title={node.title}
          excerpt={node.excerpt}
          key={node.id}
          imageSrc={(node.featuredImage) ? node.featuredImage.node : null}
          classInner="blog-index"
        />
      </div>
    ))
}

export default function BlogPostPage({ pageContext }){

  const { title, author, categorie, content, date, excerpt, yoastData, recommendations, latest, id, fullUrl } = pageContext

  let { featuredImage } = pageContext 
  if(!featuredImage) featuredImage = { localFile: "../../images/post-image.png", altText: "Placeholder Image" }
  
  const image = getImage(featuredImage.localFile)
  const parsedDate = utils.toSingleBlogDate(date)
  const recommendationsLenth = 3
  const renderedLength = recommendations.filter(el => el.databaseId !== id).length
  const recommendationsIds = utils.getFields(recommendations.filter(el => el.databaseId !== id))
  
  return (
    <Page>
      <Seo 
        title={yoastData.title}
        post={yoastData} 
      />
      <GatsbyImage image={image} className="bg-pattern-gradient w-100 feature-image" objectFit="cover" alt={featuredImage.altText} />
      <Container className="container-xg mt-4r mb-4r">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <div className="pe-md-7 pe-lg-8">
                <Tag to={categorie.uri} text={categorie.name} />
                <h1 className="c-purple">{title}</h1>
                <div className="bg-color-purple-4 round p-3 mt-1r mb-2r">
                  <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                    <div className="fs-small c-grey-2">
                      <span className="">Por:</span>
                      <span className="c-purple-2"> { utils.getAuthorName(author) }</span>
                      <span className="pl-1r">{ parsedDate }</span>
                    </div>
                    <SharePost title={title} excerpt={excerpt} url={fullUrl} />
                  </div>
                </div>
                
                <div dangerouslySetInnerHTML={{ __html: content }}  className="the-content" />
                
              </div>
              <div className="row">
                <h3 className="c-purple-2 my-4 pt-4 sep">Você também pode gostar de:</h3>
                { recommendations &&
                  renderRecomendations(id, recommendations, recommendationsLenth)
                }
                { renderedLength < recommendationsLenth &&
                  renderLatest(latest, recommendationsLenth - renderedLength, id, recommendationsIds)
                }
              </div>
            </div>
            <div className="col-lg-4">
              <Sidebar />
            </div>
          </div>
        </div>
      </Container>
    </Page>
  )
}