import * as React from "react"
import Button from "../components/Button"

export default function SharePost({url, title, excerpt}) {

  // url = (typeof window !== `undefined`) ? `${window.location.origin}/${url}` : `${url}`
  // url = `${url}`
  title = encodeURIComponent(title)
  excerpt = encodeURIComponent(excerpt)

  return (
    <div className="mt-2 mt-sm-0">
      <span className="c-purple-2 dib pe-3">Compartilhe:</span>
      {/* LinkedIn */}
      <Button to={`https://www.linkedin.com/shareArticle/?mini=true&title=${title}&url=${url}`} targetBlank>
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 2.005A2.005 2.005 0 012.005 0h19.988A2.004 2.004 0 0124 2.005v19.988A2.005 2.005 0 0121.994 24H2.005A2.006 2.006 0 010 21.994V2.005zm9.5 7.146h3.25v1.632C13.218 9.844 14.417 9 16.221 9c3.457 0 4.276 1.869 4.276 5.297v6.352H17v-5.57c0-1.953-.47-3.055-1.66-3.055-1.653 0-2.34 1.188-2.34 3.055v5.57H9.5V9.15zm-6 11.348H7V9H3.5V20.499zm4-15.25a2.25 2.25 0 11-4.5.1 2.25 2.25 0 014.5-.1z" fill="#B25E8B"/>
          </g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>
        </svg>
      </Button>
      {/* Whatsapp */}
      <Button to={`https://api.whatsapp.com/send?text=${title}${url}`} className="ml-1r" targetBlank>
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 4.864c-3.895 0-7.06 3.166-7.066 7.061a7.03 7.03 0 001.082 3.755l.166.268-.712 2.604 2.673-.702.257.155a7.043 7.043 0 003.595.986H12c3.89 0 7.141-3.166 7.141-7.06 0-1.886-.814-3.66-2.148-4.993A7.007 7.007 0 0012 4.864zm4.152 10.093c-.177.498-1.023.948-1.43 1.007-.676.102-1.2.048-2.545-.53-2.127-.921-3.52-3.064-3.627-3.204-.107-.139-.868-1.151-.868-2.196 0-1.045.547-1.559.745-1.773a.773.773 0 01.568-.268c.139 0 .284 0 .407.005.128.006.305-.048.477.365.176.423.6 1.467.653 1.575.054.107.091.23.016.37-.407.813-.84.781-.621 1.156.82 1.41 1.64 1.897 2.887 2.524.215.107.338.09.461-.054.123-.14.53-.621.67-.83.139-.215.284-.177.476-.108.193.07 1.238.584 1.452.691.214.108.354.161.407.247.049.102.049.53-.128 1.023zM21.429 0H2.57A2.572 2.572 0 000 2.571V21.43A2.572 2.572 0 002.571 24H21.43A2.572 2.572 0 0024 21.429V2.57A2.572 2.572 0 0021.429 0zm-9.434 20.421a8.488 8.488 0 01-4.061-1.034l-4.505 1.184 1.205-4.403a8.466 8.466 0 01-1.136-4.248c.006-4.683 3.814-8.491 8.497-8.491a8.44 8.44 0 016.01 2.49c1.602 1.608 2.566 3.74 2.566 6.011 0 4.683-3.894 8.491-8.576 8.491z" fill="#B25E8B"/></svg>
      </Button>
      {/* Twitter */}
      <Button to={`https://twitter.com/intent/tweet?text=${title}&url=${url}`} className="ml-1r" targetBlank>
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.04 0H.96A.959.959 0 000 .96v22.08c0 .531.429.96.96.96h22.08c.531 0 .96-.429.96-.96V.96a.959.959 0 00-.96-.96zm-4.581 8.691c.009.141.009.288.009.432 0 4.404-3.354 9.477-9.483 9.477a9.45 9.45 0 01-5.118-1.494c.27.03.528.042.804.042 1.56 0 2.994-.528 4.137-1.422a3.333 3.333 0 01-3.114-2.31c.513.075.975.075 1.503-.06a3.33 3.33 0 01-2.667-3.27v-.042c.441.249.96.402 1.503.423a3.334 3.334 0 01-1.032-4.452 9.458 9.458 0 006.87 3.483C11.4 7.233 13.092 5.4 15.126 5.4c.96 0 1.824.402 2.433 1.05a6.582 6.582 0 002.115-.801 3.316 3.316 0 01-1.464 1.833 6.59 6.59 0 001.92-.519 6.983 6.983 0 01-1.671 1.728z" fill="#B25E8B"/></svg>
      </Button>
      <Button to={`https://www.facebook.com/sharer.php?u=${url}`} className="ml-1r" targetBlank>
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.04 0H.96A.959.959 0 000 .96v22.08c0 .531.429.96.96.96h22.08c.531 0 .96-.429.96-.96V.96a.959.959 0 00-.96-.96zm-2.772 7.005h-1.917c-1.503 0-1.794.714-1.794 1.764v2.313h3.588l-.468 3.621h-3.12V24h-3.741v-9.294H9.687v-3.624h3.129v-2.67c0-3.099 1.893-4.788 4.659-4.788 1.326 0 2.463.099 2.796.144v3.237h-.003z" fill="#B25E8B"/></svg>
      </Button>
    </div>
  )
}